import request from "@/plugins/axios";

/** S 模型 **/
export const apiModelList = (params: any) =>
  request.get("/model.modelList/lists", { params });

export const apiModelAdd = (data: any) =>
  request.post("/model.modelList/add", data);

export const apiModelEdit = (data: any) =>
  request.post("/model.modelList/edit", data);

export const apiModelDetail = (params: any) =>
  request.get("/model.modelList/detail", { params });

export const apiModelDelete = (data: any) =>
  request.post("/model.modelList/del", data);
/** E 模型 **/

/** S 分类 **/
export const apiModelCategoryList = (params: any) =>
  request.get("/model.modelCategory/lists", { params });

export const apiModelCategoryAdd = (data: any) =>
  request.post("/model.modelCategory/add", data);

export const apiModelCategoryEdit = (data: any) =>
  request.post("/model.modelCategory/edit", data);

export const apiModelCategoryDetail = (params: any) =>
  request.get("/model.modelCategory/detail", { params });

export const apiModelCategoryDelete = (data: any) =>
  request.post("/model.modelCategory/del", data);
/** E 分类 **/

/** S 材质 **/
export const apiModelMaterialList = (params: any) =>
  request.get("/model.modelMaterial/lists", { params });

export const apiModelMaterialAdd = (data: any) =>
  request.post("/model.modelMaterial/add", data);

export const apiModelMaterialEdit = (data: any) =>
  request.post("/model.modelMaterial/edit", data);

export const apiModelMaterialDetail = (params: any) =>
  request.get("/model.modelMaterial/detail", { params });

export const apiModelMaterialDelete = (data: any) =>
  request.post("/model.modelMaterial/del", data);

/** E 材质 **/

/** S 颜色 **/
export const apiColorList = (params: any) =>
  request.get("/model.modelColor/lists", { params });

export const apiColorAdd = (data: any) =>
  request.post("/model.modelColor/add", data);

export const apiColorEdit = (data: any) =>
  request.post("/model.modelColor/edit", data);

export const apiColorDetail = (params: any) =>
  request.get("/model.modelColor/detail", { params });

export const apiColorDelete = (data: any) =>
  request.post("/model.modelColor/del", data);

/** E 颜色 **/
